import React from 'react'
import * as styles from './style.module.scss'

interface CardProps {
    icon: React.ReactNode
    title: string
    description: string
    hoverable?: boolean
    color?: string
}

export const CardNumberIcon = ({ number }: { number: number }) => {
    return <div className={styles.cardNumberIcon}>{number}</div>
}

export const Card = (props: CardProps) => {
    return (
        <div className={props.hoverable ? styles.cardHoverableWrapper : styles.cardWrapper}>
            <div>{props.icon}</div>
            <h4>{props.title}</h4>
            <hr style={{ borderColor: props.color || 'var(--cts-color-blue-72)' }} />
            <p>{props.description}</p>
        </div>
    )
}
