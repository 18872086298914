import * as styles from './styles.module.scss';
import {Link} from 'gatsby';
import * as globalStyles from './../../../styles/global.module.scss'
import {Container} from './../../../components/layout/Container/Container'

export default function () {
    return (
        <section className={'py-[50px] tablet:pb-[100px]'}>
            <Container>
                <div className={styles.learnCard}>
                    <h4>Want to learn more about our services? Get in touch!</h4>
                    <Link to={'#popup'} className={globalStyles.btn}>Get in touch</Link>
                </div>

            </Container>
        </section>
    )
}