import { Layout } from '../../components/layout'
import { useContext } from 'react'
import SectionHero from '../../components/sections/hero'
import { HeadFC } from 'gatsby'
import ImageHeader from './images/main-pic.png'
import { SectionCards } from './section-cards'
import SectionExpertsItems from './SectionExpertsItems'
import SectionWhyChoose from './SectionWhyChoose'
import SectionLearnAbout from './SectionLearnAbout'
import { registerDialogContext } from '../../components/shared/RegisterDialog'

const heroData = {
    title: 'Global market access',
    subtitle:
        'Access 1000+ instruments across margin and cash products. Get best-in-class execution and liquidity from a single clearing and custody account.',
    ctaText: 'Open an account',
    image: ImageHeader,
}

const IndexPage = () => {
    const { handleOpenRegisterDialog } = useContext(registerDialogContext)

    return (
        <Layout>
            <SectionHero
                title={heroData.title}
                subtitle={heroData.subtitle}
                ctaText={heroData.ctaText}
                ctaEvent={handleOpenRegisterDialog}
                image={heroData.image}
            />
            <SectionCards />
            <SectionExpertsItems />
            <SectionWhyChoose />
            <SectionLearnAbout />
        </Layout>
    )
}

const Head: HeadFC = () => (
    <>
        <title>Global market access</title>
        <meta
            name="description"
            content="Access 1000+ instruments across margin and cash products. Get best-in-class execution and liquidity from a single clearing and custody account."
        />
        <link rel="preload" href={ImageHeader} as="image" media={'screen and (resolution: 1x)'} />
        {/*<link rel="preload" href={ImageHeaderRetina} as="image" media={'screen and (min-resolution: 2x)'} />*/}
        {/*<link rel="preload" href={ImageBackground} as="image" />*/}
    </>
)

export { IndexPage, Head }
