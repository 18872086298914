import checkMarkIcon from './images/check-mark.svg';
import {Container} from "../../../components/layout/Container/Container";
import * as styles from './styles.module.scss'



const whyChooseData = [
    {
        title:'Individual approach',
        description: 'We manually handle every request, to ensure you get tailored solutions that suit your needs.',
    },
    {
        title:'Flexibility',
        description: 'We adjust our services to your needs, providing the most flexible conditions on the market.',
    },
    {
        title:'Transparency',
        description: 'Get full visibility of your trading activity, including tick data, and a comprehensive reporting suite.',
    },
    {
        title:'Scalability',
        description: 'Our solutions are backed by dynamic, cutting-edge technology, ensuring your product can continue to scale.',
    }
]



export default function () {
    return (
        <section className={styles.section}>
            <Container className={'pt-[50px] tablet:py-[50px]'}>
                <h2>Why choose us</h2>
                <div className={styles.cardsWrapper}>
                    { whyChooseData.map((card)=>(
                        <div key={card.title} className={styles.card}>
                            <img src={checkMarkIcon} alt={''} width={32} height={32}/>
                            <div>
                                <h4>{card.title}</h4>
                                <div className={'text-cts-text-1'}>{card.description}</div>
                            </div>
                        </div>
                    ))
                    }
                </div>
            </Container>

        </section>
    )
}