import { Card } from '../../../../components/shared/IconCard'
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react'
import * as globalStyles from '../../../../styles/global.module.scss'
import React, { useState } from 'react'
import './style.scss'
import 'swiper/css'
import classNames from 'classnames'


interface Card {
    icon: string
    title: string
    description: string
    // filterName: MarketDataFilterName
    color: string
}

const cardsData: Card[] = [
    {
        icon: require('../../images/icons/forex.svg').default,
        title: 'Forex',
        description: '185+ Forex pairs including majors, minors, and exotics.',
        color: '#4858EB',
        // filterName: 'Forex',
    },
    {
        icon: require('../../images/icons/commodities.svg').default,
        title: 'Commodities',
        description: 'CFDs on a range of popular Commodities including Gold, Silver, and Oil.',
        color: '#4858EB',
        // filterName: 'Commodities',
    },
    {
        icon: require('../../images/icons/indices.svg').default,
        title: 'Indices',
        description: 'Dozens of CFDs on global Stock Indices like the DAX, S&P500, and FTSE100.',
        color: '#4858EB',
        // filterName: 'Indices',
    },
    {
        icon: require('../../images/icons/shares.svg').default,
        title: 'Shares',
        description: 'CFDs on a variety of famous Stocks across Europe, Asia, and 50+ other markets.',
        color: '#4858EB',
        // filterName: 'Shares',
    },
]

interface SliderPosition {
    isBeginning: boolean
    isEnd: boolean
}

const SwiperControls = ({ position }: { position: SliderPosition }) => {
    const swiper = useSwiper()
    const controlButtonClassname = 'flex h-[24px] w-[24px] items-center justify-center'

    return (
        <div className="mt-[20px] flex items-center justify-center">
            <button
                className={classNames(controlButtonClassname, 'mr-[120px]', {
                    'opacity-[.25]': position.isBeginning,
                })}
                onClick={() => swiper.slidePrev()}
                disabled={position.isBeginning}
            >
                <img
                    className="pointer-events-none rotate-90"
                    src={require('../../../../images/icons/arrow.svg').default}
                    alt="Prev slide arrow icon"
                />
            </button>
            <button
                className={classNames(controlButtonClassname, {
                    'opacity-[.25]': position.isEnd,
                })}
                onClick={() => swiper.slideNext()}
                disabled={position.isEnd}
            >
                <img
                    className="pointer-events-none -rotate-90"
                    src={require('../../../../images/icons/arrow.svg').default}
                    alt="Next slide arrow icon"
                />
            </button>
        </div>
    )
}

export const CardsGridSlider = () => {
    const [position, setPosition] = useState<SliderPosition>({
        isBeginning: true,
        isEnd: false,
    })

    const handler = (s: any) => {
        setPosition({
            isBeginning: s.activeIndex === 0 || s.isBeginning,
            isEnd: s.activeIndex === s.slides.length - 1 || s.isEnd,
        })
    }

    return (
        <>
            <div className="hidden tablet:grid tablet:grid-cols-2 tablet:gap-y-[40px] tablet:gap-x-[20px] desktop:grid-cols-4">
                {cardsData.map((item, key) => {

                    return (
                        <Card {...item} icon={<img src={item.icon} alt={item.title} key={key} />} key={key} hoverable />
                    )
                })}
            </div>
            <div className="tablet:hidden">
                <Swiper slidesPerView={'auto'} onInit={handler} onSlideChange={handler}>
                    {cardsData.map((item, key) => {

                        return (
                            <SwiperSlide key={key}>
                              <Card {...item} icon={<img src={item.icon} alt={item.title} />} key={key} hoverable />
                            </SwiperSlide>
                        )
                    })}
                    <SwiperControls position={position} />
                </Swiper>
            </div>
            <div className={'mt-[32px] text-start p-[20px] desktop:text-center tablet:py-[10px]  bg-cts-pink rounded-[10px] text-cts-text-2'}>
                CTS Cloud Trading Solutions is Regulated by Cyprus Securities and Exchange Commission. License No. 224/14 {'  '}
                <a href="https://www.cysec.gov.cy/en-GB/entities/investment-firms/cypriot/37630/" target='_blank' className={globalStyles.link}>Read More</a>

            </div>
        </>
    )
}
