import {Container} from "../../../components/layout/Container/Container";
import {CardsGridSlider} from "../components/cards";
import React from "react";
import './sliderStyles.scss'



export const SectionCards = () => (
    <section>
        <Container className={'pt-[50px] tablet:pt-[100px] tablet:pb-[50px]'}>
            <h2 className={'mb-[32px] text-center tablet:mb-[50px]'}>Choose from a wide range of markets</h2>
            <CardsGridSlider />
        </Container>
    </section>
)