
import * as styles from './styles.module.scss'
import classNames from "classnames";
import {Container} from "../../../components/layout/Container/Container";
import React from "react";


export default function SectionExpertsItems () {
    return (
        <section>
            <Container className={'pt-[50px] tablet:py-[50px]'}>
                <h2 className={'mb-[32px] tablet:mb-[50px] text-center'}>We’re experts in technology for capital markets</h2>
                <div className={styles.cardsWrapper}>
                    <div className={styles.card}>
                        <div  className={classNames(styles.cardTopLine,styles.cardTopLineBlue)}></div>
                        <div>
                            <h4>Multi-asset trading via MT5</h4>
                            <ul>
                                <li>
                                    Get reliable market access through a stable, fast, and flexible trading platform — MT5.
                                </li>
                                <li>
                                    Use the specialised MetaEditor tool to create trading robots and technical indicators on MetaTrader5.
                                </li>
                                <li>
                                    Create strategies, scripts, trading robots, and indicators with MQL5.
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className={styles.card}>
                        <div className={classNames(styles.cardTopLine,styles.cardTopLineRed)}></div>
                        <div>
                            <h4>Bespoke trading functionality via API</h4>
                            <ul>
                                <li>
                                    Connect to and integrate with our entire capital markets infrastructure.
                                </li>
                                <li>
                                    Build bespoke, fully-integrated user experiences, or embed new products, services, and functionalities into your existing UI and infrastructure.
                                </li>
                                <li>
                                    Integrate with your own systems seamlessly with our REST API, which uses SAML and OAuth to ensure simple and secure development.
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>

            </Container>

        </section>
    )
}